import Routes from '../routes/routes'
import { buildPostApi } from './helpers'

function mapPayload (params) {
    return {
        password: params.password,
        password_repeat: params.passwordRepeat,
        reCaptcha: params.reCaptcha,
    }
}

export function mapParams ({ token }) {
    return { token }
}

export default buildPostApi({
    route: Routes.apiResetPasswordUpdate,
    mapParams,
    mapPayload,
})
