import {
    mapSearchVehicleInspectionStation,
    buildVehicleInspectionStations,
} from '../converters'
import { buildGetApi } from '../helpers'
import Routes from '../../routes/routes'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiSearchVehicleInspectionStation,
    buildResult: buildVehicleInspectionStations,
    mapParams: mapSearchVehicleInspectionStation,
    buildOptions,
})
