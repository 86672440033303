import Routes from '../routes/routes'

import { buildGetApi } from './helpers'

import buildOptions from './buildApiOptions'
import { mapBookingAvailability, buildBookingAvailability, buildBookingAvailabilityError } from './converters'

export default buildGetApi({
    route: Routes.apiGetBookingProductsAvailability,
    buildResult: buildBookingAvailability,
    mapParams: mapBookingAvailability,
    buildOptions,
    buildError: buildBookingAvailabilityError,
})
