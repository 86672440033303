export const mapRentalCarPOI = (poi) => {
    if (!poi) {
        return null
    }
    const { id, type, name } = poi
    return ({
        id,
        type,
        name,
    })
}
