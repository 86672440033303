import { formatDate } from 'commons/js/util/dateConverter'
import { DATE_FORMAT } from 'commons/js/constants'

export function buildInvoice ({ id, uid, wallet, description, date, period_year: year, period_month: month, total_qty: totalQty, pdf_file_name: pdf, number, has_excel: hasExcel, has_period: hasPeriod }) {
    return {
        id,
        uid,
        description,
        date,
        period: hasPeriod && (year !== null) && (month !== null) ? formatDate({year: year, month: month - 1, day: 1}, DATE_FORMAT) : null,
        pdf,
        totalQty,
        number,
        hasExcel,
        walletName: wallet && wallet.name,
    }
}

export function buildInvoices (data) {
    return {
        pages: 1,
        page: 1,
        items: data.map(buildInvoice),
    }
}
