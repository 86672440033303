import Routes from '../../routes/routes'
import { buildPostApi } from '../helpers'
import { buildSubscription } from './converters/subscription'
import buildOptions from '../buildApiOptions'

export default buildPostApi({
    route: Routes.apiGetFinesNotifierSubscriptionCreateForNumberPlate,
    buildResult: buildSubscription,
    mapParams: ({ subscriptionId }) => ({ subscriptionId }),
    mapPayload: ({
        userId,
        walletId,
        numberPlate,
    }) => ({
        userId,
        walletId,
        number_plate: numberPlate,
    }),
    buildOptions,
})
