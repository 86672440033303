import { DATETIME_FORMAT_WITH_SECONDS } from '../constants'
import Routes from '../routes/routes'
import { formatDay, parseTimestamp } from '../util/dateConverter'
import buildOptions from './buildApiOptions'
import { buildPostApi } from './helpers'

function mapPrebooking (prebooking) {
    return {
        id_parking: parseInt(prebooking.parkingId, 10),
        id_booking_product: parseInt(prebooking.bookingProductId, 10),
        start: formatDay(parseTimestamp(prebooking.startDate), DATETIME_FORMAT_WITH_SECONDS),
        end: formatDay(parseTimestamp(prebooking.endDate), DATETIME_FORMAT_WITH_SECONDS),
    }
}

export default buildPostApi({
    route: Routes.apiCreatePublicPrebooking,
    mapPayload: mapPrebooking,
    buildOptions,
})
