import {
    DATETIME_FORMAT,
    DATETIME_FORMAT_WITH_SECONDS_WITH_SPACE,
    BOOKED_PARKING_PASS_STATUS_CANCELLED,
    BOOKED_PARKING_PASS_STATUS_REVOKED,
} from 'commons/js/constants'

import {
    parseMoment,
    toTimestamp,
} from 'commons/js/util/dateConverter'
import { buildBookingService } from './booking-service'
import { buildChargingPoint } from './chargingPoints'
import moment from 'moment'

const dateTimeToTimestamp = (value) => toTimestamp(parseMoment(value, DATETIME_FORMAT))
const dateTimeSeasonTicketToTimestamp = (value) => toTimestamp(parseMoment(value, DATETIME_FORMAT_WITH_SECONDS_WITH_SPACE))

export function mapBooking (data) {
    return {
        id: data.id,
        startDate: dateTimeToTimestamp(data.start_date),
        endDate: dateTimeToTimestamp(data.end_date),
        bookingAddedServices: data.bookingAddedServices && data.bookingAddedServices.map(buildBookingService),
        parking: {
            id: data.parking.id,
            province: data.parking.province,
            slug: data.parking.slug,
            name: data.parking.name,
            address: data.parking.address,
            openingMethod: data.parking.opening_mode,
            isAirportParking: data.parking.is_airport_parking,
            onlyTrucksAllowed: data.parking.only_trucks_allowed,
        },
        vehicle: {
            brand: data.vehicle.brand.name,
            model: data.vehicle.model.name,
            numberPlate: data.vehicle.number_plate,
            dgtEnvironmentalLabelNumber: data.vehicle.dgt_environmental_label_number,
        },
        cancellable: data.cancellable,
        canceled: data.canceled,
        cancelableLimitDate: data.cancelableLimitDate && dateTimeToTimestamp(data.cancelableLimitDate),
        priceQty: data.price_qty,
    }
}

export function mapWorkshopBooking (data) {
    return {
        id: data.id,
        code: data.code,
        startDate: data.date,
        endDate: null,
        vehicle: {
            brand: data.vehicle.brand,
            model: data.vehicle.model,
            numberPlate: data.vehicle.number_plate,
            description: data.vehicle_version_description,
            dgtEnvironmentalLabelNumber: data.vehicle.dgt_environmental_label_number,
        },
        cancellable: data.isCancelable,
        canceled: data.canceled,
        cancelableLimitDate: data.cancelableLimitDate && data.cancelableLimitDate,
        priceQty: data.price,
        product: data.estimate && data.estimate.lines && data.estimate.lines[0] && data.estimate.lines[0].name,
        workshop: {
            name: data.workshop.name,
            address: data.workshop.address,
            timezone: data.workshop.timezone,
        },
    }
}

export function mapRotation (data) {
    return {
        id: data.id,
        startDate: dateTimeSeasonTicketToTimestamp(data.start_date),
        endDate: data.end_date ? dateTimeSeasonTicketToTimestamp(data.end_date) : null,
        parking: {
            id: data.parking.id,
            province: data.parking.province,
            openingModeTitle: data.parking.opening_mode_title,
            openingModeDescription: data.parking.opening_mode_description,
            name: data.parking.name,
            slug: data.parking.slug,
            address: data.parking.address,
            openingMethod: data.parking.opening_mode,
            isAirportParking: data.parking.is_airport_parking,
            onlyTrucksAllowed: data.parking.only_trucks_allowed,
        },
        vehicle: {
            brand: data.vehicle.brand.name,
            model: data.vehicle.model.name,
            numberPlate: data.vehicle.number_plate,
            dgtEnvironmentalLabelNumber: data.vehicle.dgt_environmental_label_number,
        },
        priceQty: data.price_qty,
    }
}

export function mapSerTicket (data) {
    return {
        id: data.id,
        startDate: data.start_date,
        endDate: data.end_date,
        zone: {
            name: data.zone.name,
            id: data.zone.id,
        },
        rate: data.rate
            ? {
                name: data.rate.name,
            }
            : null,
        vehicle: {
            brand: data.vehicle.brand.name,
            model: data.vehicle.model.name,
            numberPlate: data.vehicle.number_plate,
            dgtEnvironmentalLabelNumber: data.vehicle.dgt_environmental_label_number,
        },
        priceQty: data.totalAmountQty,
        unparkingPriceQty: data.unparkingTotalAmountQty,
        stayDuration: data.stay_duration,
        unparkingStayDuration: data.unparking_stay_duration,
        timeZone: data.timeZone,
        town: data.zone.town,
    }
}

export function mapParkingPass (data) {
    return {
        id: data.id,
        startDate: data.start_date,
        endDate: data.end_date,
        parking: {
            id: data.parking.id,
            province: data.parking.province,
            name: data.parking.name,
            slug: data.parking.slug,
            address: data.parking.address,
            openingMethod: data.parking.opening_mode,
            isAirportParking: data.parking.is_airport_parking,
            onlyTrucksAllowed: data.parking.only_trucks_allowed,
        },
        vehicle: {
            brand: data.vehicle.brand.name,
            model: data.vehicle.model.name,
            numberPlate: data.vehicle.number_plate,
            dgtEnvironmentalLabelNumber: data.vehicle.dgt_environmental_label_number,
        },
        canceled: data.status === BOOKED_PARKING_PASS_STATUS_REVOKED || data.status === BOOKED_PARKING_PASS_STATUS_CANCELLED,
        priceQty: data.final_price,
        numberOfAllowedStays: data.number_of_allowed_stays,
        usesLeft: data.uses_left,
        name: data.name,
    }
}
export function mapSeasonTicket (data) {
    return {
        id: data.id,
        endDate: data.end_date ? dateTimeSeasonTicketToTimestamp(data.end_date) : null,
        startDate: dateTimeSeasonTicketToTimestamp(data.start_date),
        parking: {
            id: data.parking.id,
            province: data.parking.province,
            name: data.parking.name,
            slug: data.parking.slug,
            address: data.parking.address,
            openingMethod: data.parking.opening_mode,
            isAirportParking: data.parking.is_airport_parking,
            onlyTrucksAllowed: data.parking.only_trucks_allowed,
            timeZone: data.parking.time_zone_name,
        },
        vehicle: {
            brand: data.vehicle.brand.name,
            model: data.vehicle.model.name,
            numberPlate: data.vehicle.number_plate,
            dgtEnvironmentalLabelNumber: data.vehicle.dgt_environmental_label_number,
        },
        seasonTicket: {
            timeTables: data.seasonTicket.timeTable,
            name: data.seasonTicket.name,
        },
        canceled: data.canceled,
    }
}

export function mapVehicleInspectionBooking (data) {
    const expired = data.date
        ? (parseMoment().diff(moment(data.date * 1000)) < 0)
        : false
    return {
        station: {
            name: data.vehicleInspectionStation.name,
            address: data.vehicleInspectionStation.company_address,
        },
        cancellable: data.isCancelable,
        id: data.id,
        priceQty: data.final_price,
        cancelableLimitDate: data.expiration_date ? data.expiration_date : null,
        requiresAppointment: data.vehicleInspectionStationProduct.requires_appointment,
        startDate: data.date,
        expired,
        vehicle: {
            brand: data.vehicle.brand.name,
            model: data.vehicle.model.name,
            numberPlate: data.vehicle.number_plate,
            dgtEnvironmentalLabelNumber: data.vehicle.dgt_environmental_label_number,
        },
        canceled: data.cancelled,
    }
}

export function mapChargingPointBooking (data) {
    return {
        chargingPoint: buildChargingPoint(data.chargingPoint),
        cancellable: data.isCancelable,
        id: data.id,
        priceQty: data.total_qty,
        cancelableLimitDate: data.cancelableLimitDate,
        endDate: data.end_date ? dateTimeToTimestamp(data.end_date) : null,
        startDate: dateTimeToTimestamp(data.start_date),
        vehicle: {
            brand: data.vehicle.brand.name,
            model: data.vehicle.model.name,
            numberPlate: data.vehicle.number_plate,
            dgtEnvironmentalLabelNumber: data.vehicle.dgt_environmental_label_number,
        },
        canceled: data.cancelled,
    }
}
