import { buildPoints } from './points'
import { buildFinesRequests } from './finesRequests'
import { FINES_NOTIFIER_PRICES_STATUS_ACTIVE } from 'commons/js/constants'

export function buildSubscription (subscription) {
    return {
        dni: subscription.dni,
        driving_license_expedition_date: subscription.driving_license_expedition_date,
        endDate: subscription.end_date,
        finesRequests: subscription.finesRequests && subscription.finesRequests.map(buildFinesRequests),
        id: subscription.id,
        countryId: subscription.id_country,
        idFinesNotifierPrice: subscription.id_fines_notifier_price,
        userId: subscription.id_user,
        walletId: subscription.id_wallet,
        nextFinesRequestDate: subscription.next_fines_request_date,
        nextPaymentDate: subscription.next_payment_date,
        nextPointsRequestDate: subscription.next_points_request_date,
        numberPlate: subscription.number_plate,
        pointsRequests: subscription.pointsRequests && subscription.pointsRequests.map(buildPoints),
        price: subscription.price,
        startDate: subscription.start_date,
        status: subscription.status,
        type: subscription.subscription_type,
        timeZone: subscription.time_zone_name,
        cancelled: subscription.status !== FINES_NOTIFIER_PRICES_STATUS_ACTIVE,
    }
}
