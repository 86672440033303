import { buildParking } from './parking'
import { buildVehicle } from './vehicle'
import { BOOKED_PARKING_PASS_STATUS_CANCELLED, BOOKED_PARKING_PASS_STATUS_REVOKED } from 'commons/js/constants'

export function buildBookedParkingPass (data) {
    return {
        id: data.id,
        startDate: data.start_date,
        endDate: data.end_date,
        parking: buildParking(data.parking),
        vehicle: buildVehicle(data.vehicle),
        cancelled: data.status === BOOKED_PARKING_PASS_STATUS_REVOKED || data.status === BOOKED_PARKING_PASS_STATUS_CANCELLED,
        priceQty: data.final_price,
        numberOfAllowedStays: data.number_of_allowed_stays,
        usesLeft: data.uses_left,
        name: data.name,
        isCancellable: data.is_cancellable,
        allowedStays: data.number_of_allowed_stays,
        uses: data.current_number_of_uses,
        status: data.status,
        description: data.description,
    }
}
