import { buildFineReport } from './fineReport'

export function buildFinesRequests (item) {
    return {
        id: item.id,
        idFinesNotifierSubscription: item.id_fines_notifier_subscription,
        date: item.date,
        fineNotifications: item.fineNotifications && item.fineNotifications.map(buildFineReport),
    }
}
