import Routes from '../../routes/routes'
import { buildPutApi } from '../helpers'
import { buildSubscription } from './converters/subscription'
import buildOptions from '../buildApiOptions'

export default buildPutApi({
    route: Routes.apiFinesNotifierSubscriptionUpdateLicenseDate,
    buildResult: buildSubscription,
    mapParams: ({ subscriptionId }) => ({ subscriptionId }),
    mapPayload: ({ date }) => ({
        driving_license_expedition_date: date,
    }),
    buildOptions,
})
