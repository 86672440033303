import Routes from '../routes/routes'
import { buildGetApi } from './helpers'
import { buildSerStepsInfo } from './converters/serStep'

function buildError (error) {
    const errorStatus = error.response ? error.response.status : error.name
    return {
        status: errorStatus,
        ...error.response,
    }
}

export default buildGetApi({
    route: Routes.apiGetSerSteps,
    buildResult: buildSerStepsInfo,
    buildError: buildError,
    mapParams: ({ zoneId, rateId, vehicleId }) => ({
        zoneId,
        rateId,
        vehicleId,
    }),
})
