import Routes from '../../routes/routes'
import { buildPois } from '../converters'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiSearchPoisWithEvents,
    buildResult: buildPois,
    buildOptions,
})
