import Routes from '../../routes/routes'
import { buildPostApi } from '../helpers'
import buildOptions from './buildOptions'

export default buildPostApi({
    route: Routes.apiUserServiceRegister,
    mapParams: ({ email, name, lastName, password, validationCode }) => ({
        email,
        name,
        lastName,
        password,
        validationCode,
    }),
    buildResult: () => true,
    buildOptions,
})
