import Routes from '../routes/routes'

import { buildDelApi } from './helpers'

import { buildBooking, mapBooking } from './converters/booking'
import buildOptions from './buildApiOptions'

export default buildDelApi({
    route: Routes.apiCancelBookingPath,
    buildResult: buildBooking,
    mapParams: mapBooking,
    buildOptions,
})
