import Routes from '../../routes/routes'
import { buildGetApi } from '../helpers'
import buildOptions from './buildOptions'

export default buildGetApi({
    route: Routes.apiUserServiceTerms,
    buildResult: ({
        version,
        title,
        content,
        hash,
    }) => ({
        version,
        title,
        content,
        hash,
    }),
    buildOptions,
})
