import Routes from '../../routes/routes'
import { buildPostApi } from '../helpers'
import buildOptions from './buildOptions'
import { buildRentalCarVehicles } from '../converters/rentalCar'
import { RENTAL_CAR_VEHICLE_TYPE_CAR } from 'commons/js/constants'
import getEntryChannel from './getEntryChannel'
const mapPoi = (poi) => {
    if (!poi) {
        return null
    }
    const { id, type, name } = poi
    return ({
        id,
        type,
        name,
    })
}

const mapPayload = ({
    age,
    baggage,
    categoryList,
    distanceExtraKm,
    end_date: endDate,
    end_date_locale: endLocalDate,
    fuelType,
    gearBox,
    idDestinationPOI,
    idOriginPOI,
    isMutuaUser,
    passengers,
    start_date: startDate,
    start_date_locale: startLocalDate,
    vehicleType,
    searchId,
}) => {
    const entryChannel = getEntryChannel()
    const filters = {
        categoryList: categoryList || null,
        distanceExtraKm: distanceExtraKm || null,
        fuelType: fuelType || null,
        gearBox: gearBox || null,
        baggage: baggage || null,
        passengers: passengers || null,
        vehicleType: vehicleType || RENTAL_CAR_VEHICLE_TYPE_CAR,
        entryChannel,
    }

    const search = {
        age,
        startDate,
        startLocalDate,
        endDate,
        endLocalDate,
        idOriginPOI,
        idDestinationPOI,
        isMutuaUser: isMutuaUser === 'true',
        vehicleType,
        entryChannel,
        categoryList: categoryList || null,
        ...filters,
    }

    return searchId ? filters : search
}

export default buildPostApi({
    route: Routes.apiCarRentalGetRates,
    mapPayload,
    buildResult: (result) => {
        const { search, vehicles } = result && result.response
        const originPlaceName = search?.originPOI?.name
        return (vehicles && search)
            ? {
                id: search.id,
                idAnonymous: search.idAnonymous,
                filters: search.filters,
                startDate: search.startDate && search.startDate / 1000,
                endDate: search.endDate && search.endDate / 1000,
                timeZone: search.timeZone,
                filtersConfig: search.filtersConfig,
                originPOI: mapPoi(search.originPOI),
                destinationPOI: mapPoi(search.destinationPOI),
                vehicles: vehicles.map((vehicle) => buildRentalCarVehicles({...vehicle, place: originPlaceName})),
            } : {}
    },
    buildOptions,
})
