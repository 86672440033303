
import Routes from 'commons/js/routes/routes'
import { buildRentalCarContract } from '../converters/rentalCarContract'
import { buildGetApi } from '../helpers'
import buildOptions from './buildOptions'

export default buildGetApi({
    route: Routes.apiCarRentalGetBooking,
    buildResult: buildRentalCarContract,
    mapParams: (data) => ({ id: data.id }),
    buildOptions,
})
