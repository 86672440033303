import Routes from '../../routes/routes'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'

function mapParams ({ stationId, stationProductId }) {
    return {
        stationId,
        stationProductId,
    }
}

export default buildGetApi({
    route: Routes.apiGetVehicleInspectionStationOccupationForDays,
    mapParams,
    buildOptions,
    buildResult: (list) => list.map(timestamp => parseInt(timestamp)),
})
