import Routes from '../../routes/routes'
import { buildVehicleInspectionBooking } from '../converters'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiRequestVehicleInspectionBooking,
    buildResult: buildVehicleInspectionBooking,
    mapParams: ({ bookingId }) => ({ bookingId }),
    buildOptions,
})
