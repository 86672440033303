export function buildBookingService (service) {
    return {
        id: `${service.id}`,
        type: service.booking_service_type,
        name: service.name,
        description: service.description,
        priceQty: service.price_qty,
        transferTime: service.transfer_time,
        transferTimeFrequency: service.transfer_time_frequency,
        checked: service.selected_by_default,
        mandatory: service.mandatory,
        form: service.form,
        formFields: service.formFields || [],
        idBookingService: service.id_booking_service,
        idBookingServiceType: service.booking_service_type,
    }
}

export function mapBookingService (service) {
    return {
        id_parking_booking_service: parseInt(service.serviceId, 10),
        form: JSON.stringify(service.form),
    }
}
