import Routes from '../../routes/routes'
import { buildSeasonTicketContract } from '../converters/seasonTicketContract'
import { buildPostApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildPostApi({
    route: Routes.apiCreateSeasonTicketContract,
    mapParams: () => {},
    buildResult: buildSeasonTicketContract,
    mapPayload: ({ walletId, identifier, start, vehicleId, seasonTicketId }) => ({
        id_season_ticket: seasonTicketId,
        id_vehicle: vehicleId,
        id_wallet: walletId,
        start,
        identifier: identifier,
    }),
    buildOptions,
})
