import { buildParking } from './parking'
import { buildVehicle } from './vehicle'
import moment from 'moment-timezone'
import { DATETIME_FORMAT, DAYS } from 'commons/js/constants'
import { formatDate, formatTimestamp } from 'commons/js/util/dateConverter'

const toTimestamp = (date, timeZone = null) => date
    ? parseInt(formatTimestamp(moment.tz(formatDate(date, DATETIME_FORMAT), timeZone)))
    : null

const getResume = (timeTables) => {
    return timeTables.reduce((resume, timetable) => {
        DAYS.forEach((day) => {
            const editedDay = resume.find(({name}) => name === day)
            editedDay.hours = [ ...timetable[`${day}`] ]
            editedDay.open = !!timetable[`${day}`].length
        })
        return resume
    }, DAYS.map((name) => ({name, open: false, hours: []})))
}

export function buildSeasonTicketResume (data) {
    return {
        id: data.id,
        endDate: data.end_date || null,
        startDate: data.start_date,
        parking: buildParking(data.parking),
        vehicle: data.vehicle ? buildVehicle(data.vehicle) : null,
        seasonTicket: {
            name: data.season_ticket.name,
        },
        canceled: data.canceled,
    }
}

export function buildSeasonTicketContract (data) {
    const seasonTicket = data.season_ticket || data.seasonTicket
    return {
        id: data.id,
        cancellable: data.cancellable && !data.cancelled,
        identifier: data.identifier,
        cancelled: data.cancelled,
        endDate: toTimestamp(data.end_date, data.parking.timeZone),
        startDate: toTimestamp(data.start_date, data.parking.timeZone),
        timeZone: data.parking.timeZone,
        vehicle: buildVehicle(data.vehicle),
        parking: buildParking(data.parking),
        parkingLot: data.parkingLot,
        seasonTicket: seasonTicket && {
            name: seasonTicket.name,
            timeTableResume: seasonTicket.timeTable && getResume([seasonTicket.timeTable]),
        },
        payments: data.payments && data.payments.map((payment) => ({
            id: payment.id,
            qty: payment.qty,
            date: toTimestamp(payment.date, data.parking.timeZone),
        })),
        hasPromotionalPrice: data.has_promotional_price,
        promotionalPrice: data.promotional_price_qty,
        promotionalPriceEndDate: toTimestamp(data.promotional_price_end_date, data.parking.timeZone),
        nextPaymentDate: toTimestamp(data.next_payment_date, data.parking.timeZone),
        priceQty: seasonTicket && seasonTicket.price_qty,
    }
}
