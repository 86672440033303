import Routes from '../../routes/routes'
import { buildParkings, mapSearchParkings } from '../converters'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiSearchParkings,
    mapParams: mapSearchParkings,
    buildResult: buildParkings,
    buildOptions,
})
