import { toTimestamp } from 'commons/js/util/dateConverter'

export function mapPrebooking ({ id }) {
    return {
        idPrebooking: id,
    }
}

export function mapUpdatePrebooking ({ prebooking }) {
    return {
        id_user: prebooking.userId,
    }
}

export function buildPrebooking (prebooking) {
    return {
        id: prebooking.id,
        parkingId: prebooking.id_parking,
        bookingProductId: prebooking.id_booking_product,
        userId: prebooking.id_user,
        startDate: toTimestamp(prebooking.start),
        endDate: toTimestamp(prebooking.end),
        cancelableLimitDate: prebooking.cancelableLimitDate,
    }
}
