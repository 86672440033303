import Routes from '../routes/routes'
import { buildPutApi } from './helpers'

function mapPayload (data) {
    return {
        old_password: data.passwordOld,
        password: data.passwordNew,
    }
}

export default buildPutApi({
    route: Routes.apiUpdatePassword,
    mapParams: () => {},
    mapPayload,
})
