import { DATETIME_FORMAT_WITH_SPACE } from 'commons/js/constants'
import { parseMoment, toTimestamp } from 'commons/js/util/dateConverter'
import { buildVehicle } from './vehicle'

const parseDate = (date) => {
    if (typeof date === 'string') {
        return toTimestamp(parseMoment(date, DATETIME_FORMAT_WITH_SPACE))
    }
    return date
}

export function buildFine (data) {
    return {
        id: data.id,
        date: parseDate(data.date || null),
        dueDate: parseDate(data.due_date || null),
        vehicle: data.vehicle ? buildVehicle(data.vehicle) : null,
        town: data.SERTown ? {
            id: data.SERTown.id,
            name: data.SERTown.name,
            timeZone: data.SERTown.timeZone,
        } : null,
        article: data.article,
        description: data.description,
        fineType: data.fine_type,
        parking: data.parking ? {
            id: data.parking.id,
            name: data.parking.name,
            timeZone: data.parking.time_zone_name,
        } : null,
        bulletinNumber: data.bulletin_number,
        fareQty: data.fare_qty,
        commissionQty: data.commission_qty,
        totalQty: data.total_qty,
        timeZone: data.timeZone,
        commission: data.commission,
        commissionVat: data.commission_vat,
        commissionVatName: data.commission_vat_name,
    }
}
