import Routes from '../routes/routes'
import { buildGetApi } from './helpers'
import { parseTimestamp, formatDay } from 'commons/js/util/dateConverter'
import { DATETIME_FORMAT_WITH_SECONDS_WITH_SPACE } from 'commons/js/constants'
import buildOptions from './buildApiOptions'

function mapParams ({ bookingId, startDate, endDate }) {
    return {
        idBooking: bookingId,
        start_date: formatDay(
            parseTimestamp(startDate),
            DATETIME_FORMAT_WITH_SECONDS_WITH_SPACE,
        ),
        end_date: formatDay(
            parseTimestamp(endDate),
            DATETIME_FORMAT_WITH_SECONDS_WITH_SPACE,
        ),
    }
}

export default buildGetApi({
    route: Routes.apiGetBookingAvailabilityUpdateDates,
    buildResult: ({
        actual_total_qty: priceQty,
        total_qty_difference: difference,
        bookingAvailability,
    }) => ({
        priceQty,
        difference,
        effectiveEndDate: bookingAvailability.effective_end_date,
    }),
    mapParams,
    buildOptions,
})
