import Routes from '../routes/routes'
import { buildInvoices } from './converters/invoice'
import { buildGetApi } from './helpers'
import buildOptions from './buildApiOptions'

export default buildGetApi({
    route: Routes.apiRequestInvoices,
    buildResult: buildInvoices,
    buildOptions,
})
