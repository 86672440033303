export function buildSerStep (step) {
    return {
        id: step.id,
        minute: step.minute,
        time: step.time,
        fareQty: step.fare_qty,
        commissionQty: step.commission_qty,
        totalQty: step.total_qty,
        fixedMinimumApplied: step.fixed_minimum_applied,
        originalNonDiscountedPriceQty: step.original_non_discounted_price_qty,
        priceIfNoFreeMinutesWereUsed: step.price_if_no_free_minutes_were_used,
        priceModifierDescriptions: step.price_modifier_descriptions,
        mainPriceModifierDescription: step.main_price_modifier_description,
        endDate: step.end_date,
        freeMinutesUsed: step.free_minutes_used,
        timeZone: step.timeZone,
    }
}
export function mapSerStep (step) {
    return {
        id: step.id,
        minute: step.minute,
        time: step.time,
        fare_qty: step.fareQty,
        commission_qty: step.commissionQty,
        total_qty: step.totalQty,
        fixed_minimum_applied: step.fixedMinimumApplied,
        original_non_discounted_price_qty: step.originalNonDiscountedPriceQty,
        price_if_no_free_minutes_were_used: step.priceIfNoFreeMinutesWereUsed,
        main_price_modifier_description: step.mainPriceModifierDescription,
        end_date: step.endDate,
        free_minutes_used: step.freeMinutesUsed,
    }
}

export function buildSerStepsInfo (data) {
    return {
        startTime: data.start_time,
        timeZone: data.time_zone,
        requestDate: data.requestDate,
        maxTime: data.max_time,
        pollutionCoefficient: data.pollution_coefficient ? (data.pollution_coefficient * 100 - 100) : null,
        vehicleClass: data.vehicle_class,
        occupancyLevel: data.occupancy_level,
        securityChecksum: data.security_checksum,
        rawSteps: data.raw_steps,
        discount: data.discount,
        discountType: data.discount_type,
        commission: data.commission,
        vat: data.vat,
        taxName: data.tax_name,
        isExtension: data.is_extension,
        totalUserFreeMinutes: data.total_user_free_minutes,
        steps: data.steps.map(buildSerStep),
        remoteAuthorizationId: data.remote_authorization_id,
        remotePostPaymentOperation: data.remote_post_payment_operation,
        remoteSessionId: data.remote_session_id,
    }
}

export function mapSerStepsInfo (data) {
    return {
        start_time: data.startTime,
        time_zone: data.timeZone,
        requestDate: data.requestDate,
        max_time: data.maxTime,
        pollution_coefficient: data.pollutionCoefficient ? (data.pollutionCoefficient + 100) / 100 : null,
        vehicle_class: data.vehicleClass,
        occupancy_level: data.occupancyLevel,
        security_checksum: data.securityChecksum,
        raw_steps: data.rawSteps,
        discount: data.discount,
        discount_type: data.discountType,
        commission: data.commission,
        vat: data.vat,
        tax_name: data.taxName,
        is_extension: data.isExtension,
        total_user_free_minutes: data.totalUserFreeMinutes,
        steps: data.steps.map(mapSerStep),
        remote_authorization_id: data.remoteAuthorizationId,
        remote_post_payment_operation: data.remotePostPaymentOperation,
        remote_session_id: data.remoteSessionId,
    }
}
