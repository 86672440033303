export const getIntFromString = (st) => {
    if (!st) {
        return null
    }
    const values = st.split(' ')[0]
    return parseInt(values[0]) || null
}

export const buildRentalCarRates = ({
    additionalInfoUrl,
    id,
    coverageTags,
    currencySymbol,
    displayRateQualifierName,
    price,
    rateQualifier,
}) => ({
    id,
    additionalInfoUrl,
    currencySymbol,
    name: displayRateQualifierName,
    rateQualifier,
    price: {
        amount: price,
        currency: currencySymbol,
    },
    oldPrice: null,
    features: coverageTags,
})

export const buildRentalCarVehicles = ({
    baggage,
    category,
    categoryDescription,
    categoryLogoUrl,
    categoryName,
    companyLogoUrl,
    distanceMeters,
    doors,
    fuel,
    gearBox,
    id,
    idSupplier,
    seats,
    vehicleCode,
    cancelFree,
    rates,
    place,
    supplierName,
}) => ({
    id,
    type: category,
    description: categoryDescription,
    freeCancel: cancelFree === undefined ? true : cancelFree,
    place: {
        name: place,
        distance: distanceMeters || 0,
    },
    seller: {
        id: idSupplier,
        name: supplierName,
        image: companyLogoUrl,
    },
    car: {
        type: categoryName,
        description: categoryDescription,
        image: categoryLogoUrl || '',
        features: {
            oilType: fuel,
            gear: gearBox,
            doors: getIntFromString(doors),
            seats: getIntFromString(seats),
            suitcase: getIntFromString(baggage),
        },
        id: vehicleCode,
        rates: rates.map((rate) => buildRentalCarRates(rate)),
    },
})
