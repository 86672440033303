import Routes from '../../routes/routes'
import { buildSeasonTicketContract } from '../converters/seasonTicketContract'
import { buildDelApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildDelApi({
    route: Routes.apiCancelSeasonTicketContractPath,
    mapParams: (data) => ({ idContract: data.seasonTicketContractId }),
    buildResult: buildSeasonTicketContract,
    buildOptions,
})
