import Routes from '../../routes/routes'
import { buildGetApi } from '../helpers'
import { buildPointReport } from './converters/pointsReport'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiFinesNotifierNotificationsGetPointReport,
    buildResult: buildPointReport,
    buildOptions,
})
