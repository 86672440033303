import { buildVehicle } from './vehicle'
import { buildMobe } from './mobe'
import {
    parseMoment,
    toTimestamp,
} from 'commons/js/util/dateConverter'
const dateTimeToTimestamp = (value) => value && toTimestamp(parseMoment(value))

export function buildToll (toll) {
    const taxQty = (toll.tax_qty && toll.tax_qty.amount) || toll.tax_amount
    return {
        id: toll.id,
        mobe: buildMobe({
            ...toll.mobe,
            vehicle: toll.vehicle,
        }),
        entrance: (toll.date_entrance && isNaN(toll.date_entrance)) ? dateTimeToTimestamp(toll.date_entrance) : toll.date_entrance,
        exit: (toll.date_exit && isNaN(toll.date_exit)) ? dateTimeToTimestamp(toll.date_exit) : toll.date_exit,
        highway: toll.highway_name,
        basePrice: toll.base_price,
        description: toll.description,
        priceQty: {
            amount: Math.abs(toll.price_qty.amount),
            currency: toll.price_qty.currency,
        },
        type: toll.type,
        showBreakdown: toll.showBreakdown,
        timeZone: toll.timezone,
        basePriceQty: {
            amount: toll.base_price,
            currency: toll.price_qty.currency,
        },
        tax: {
            qty: taxQty ? {
                amount: Math.abs(taxQty),
                currency: toll.price_qty.currency,
            } : null,
            name: toll.tax_name,
            percentage: toll.tax_percentage,
        },
        vehicle: toll.vehicle ? buildVehicle(toll.vehicle) : null,
    }
}
