import Routes from '../routes/routes'
import { buildDelApi } from './helpers'
import { buildBookedParkingPass } from './converters/bookedParkingPass'
import buildOptions from './buildApiOptions'

export default buildDelApi({
    route: Routes.apiCancelBookedParkingPassPath,
    buildResult: buildBookedParkingPass,
    mapParams: ({ id, idUser }) => ({ passId: id, idUser }),
    buildOptions,
})
