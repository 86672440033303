import Routes from '../../routes/routes'
import { buildPutApi } from '../helpers'
import buildOptions from './buildOptions'
import { buildRentalCarBooking } from '../converters/rentalCarBooking'
export function mapPayload ({extras = [], coverages = []}) {
    return {
        extras: [
            ...coverages,
            ...extras,
        ],
    }
}

export function mapParams ({id}) {
    return { id }
}

export default buildPutApi({
    route: Routes.apiCarRentalBuildBooking,
    mapPayload,
    mapParams,
    buildOptions,
    buildResult: ({response}) => {
        const { unborn } = response
        return buildRentalCarBooking(unborn)
    },
})
