import Routes from '../../routes/routes'
import { buildAirports } from '../converters'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiSearchAirports,
    buildResult: buildAirports,
    buildOptions,
})
