import Routes from '../routes/routes'
import { buildPutApi } from './helpers'
import { buildBooking } from './converters'
import { parseTimestamp, formatDay } from 'commons/js/util/dateConverter'
import { DATETIME_FORMAT_WITH_SECONDS } from 'commons/js/constants'
import buildOptions from './buildApiOptions'

function mapParams ({ bookingId }) {
    return {
        idBooking: bookingId,
    }
}

function mapPayload ({ walletId, startDate, endDate }) {
    return {
        id_wallet: walletId,
        start_date: formatDay(
            parseTimestamp(startDate),
            DATETIME_FORMAT_WITH_SECONDS
        ),
        end_date: formatDay(
            parseTimestamp(endDate),
            DATETIME_FORMAT_WITH_SECONDS,
        ),
    }
}

export default buildPutApi({
    route: Routes.updateBookingDates,
    buildResult: buildBooking,
    mapPayload,
    mapParams,
    buildOptions,
})
