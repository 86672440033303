import Routes from '../routes/routes'
import { buildGetApi } from './helpers'
import { buildCard } from './converters/creditCard'

function mapParams ({ id, paymentGatewayId, birthday }) {
    return {
        setUpIntentId: id,
        gatewayId: paymentGatewayId,
        birthday,
    }
}

export default buildGetApi({
    route: Routes.apiGetCreditCardInfo,
    buildResult: buildCard,
    mapParams,
})
