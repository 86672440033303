import {
    buildParking,
 } from './parking'

export function buildOfferedParkingPass (data) {
    return {
        id: data.id,
        name: data.name,
        description: data.description,
        qty: data.final_price,
        allowedStays: data.number_of_allowed_stays,
        parking: buildParking(data.parking),
    }
}
