import { buildBooking } from './booking'
import { buildSeasonTicketResume } from './seasonTicketContract'

export function mapRotation ({ rotationId }) {
    return {
        idRotation: rotationId,
    }
}

export function buildRotation (rotation) {
    return {
        id: rotation.id,
        startDate: rotation.start_date,
        endDate: rotation.end_date,
        priceQty: rotation.price_qty,
        parking: {
            ...rotation.parking,
            openingModeTitle: rotation.parking.opening_mode_title,
            openingModeDescription: rotation.parking.opening_mode_description,
            openingMethod: rotation.parking.opening_mode,
            isAirportParking: rotation.parking.is_airport_parking,
        },
        vehicle: {
            ...rotation.vehicle,
            numberPlate: rotation.vehicle.number_plate,
        },
        booking: rotation.booking ? buildBooking(rotation.booking) : null,
        seasonTicketContract: rotation.seasonTicketContract ? buildSeasonTicketResume(rotation.seasonTicketContract) : null,
    }
}
