import Routes from '../routes/routes'
import { buildSessionUser } from './converters/user'
import { buildPostApi } from './helpers'

function mapPayload (login) {
    return {
        email: login.email,
        password: login.password,
        rememberMe: login.rememberMe,
        reCaptcha: login.reCaptcha,
    }
}

function buildResult (user) {
    return buildSessionUser(user)
}

export default buildPostApi({
    route: Routes.login,
    mapPayload,
    buildResult,
})
