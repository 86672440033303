import Routes from '../../routes/routes'
import { buildPutApi } from '../helpers'
import { buildSeasonTicketContract } from '../converters/seasonTicketContract'
import buildOptions from '../buildApiOptions'

export default buildPutApi({
    route: Routes.apiUpdateVehicleSeasonTicketContractPath,
    buildResult: buildSeasonTicketContract,
    mapParams: ({ id, vehicleId }) => ({ id, vehicleId }),
    buildOptions,
})
