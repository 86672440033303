import { buildBooking } from './booking'
import { buildUser } from './user'
import { mapSerTicket, mapParkingPass } from './contracts'
import { buildSeasonTicketResume } from './seasonTicketContract'
import { WALLET_MOVEMENTS } from 'commons/js/constants'
import { buildFine } from './fine'
import { buildChargingPointBooking } from './chargingPointBooking'
import { buildWorkshopBooking } from './workshopBooking'
import { buildVehicleInspectionBooking } from './vehicleInspectionBooking'
import { buildRotation } from './rotation'
import { buildCouponOrder, buildCouponOrderFee } from './couponOrder'
import { buildRefuelTicket } from './refuelTicket'
import { buildDeposits, buildDepositCoupon } from './deposits'
import { buildTakseeTransaction } from './takseeTransaction'
import { buildToll } from './toll'

export function buildWallets (wallets) {
    return wallets.map(({ is_admin: isAdmin, preferences, ...restProps }) => ({
        isAdmin,
        ...restProps,
        preferences: preferences && buildWalletPreferences(preferences),
    }))
}

export function buildWallet (wallet) {
    return {
        ...wallet,
        country: {
            ...wallet.country,
            countryIso3: wallet.country.long_code,
            countryName: wallet.country.name,
        },
        isAdmin: wallet.is_admin,
        isBusiness: wallet.is_business,
        backendCorporateEnabled: wallet.backend_corporate_enabled,
        corporatePlatesWhitelistEnabled: wallet.corporate_plates_whitelist_enabled,
        preferences: wallet.preferences && buildWalletPreferences(wallet.preferences),
        isAutoDepositAllowed: !!wallet.is_auto_deposit_allowed,
    }
}

export function mapFetchWalletParams (walletId) {
    return {
        idWallet: walletId,
    }
}

export function buildWalletPreferences ({
    id_wallet: idWallet,
    auto_deposit: autoDeposit,
    auto_deposit_amount_qty: amountQty,
    auto_deposit_threshold_qty: thresholdQty,
}) {
    return {
        idWallet,
        autoDeposit,
        amountQty,
        thresholdQty,
    }
}

export function mapWorkshopPreferences (params) {
    return {
        id_wallet: params.idWallet,
        auto_deposit: params.autoDeposit,
        auto_deposit_threshold_qty: params.thresholdQty,
        auto_deposit_amount_qty: params.amountQty,
    }
}

export function buildTiers ({
    auto_deposit_amounts_qty: amounts,
    auto_deposit_thresholds_qty: thresholds,
}) {
    return {
        amounts,
        thresholds,
    }
}

function buildEntity (movement) {
    const { type_entity: type, entity } = movement
    switch (type) {
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_WORKSHOP_BOOKING:
            return buildWorkshopBooking(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_CHARGING_BOOKING:
            return buildChargingPointBooking(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_BOOKING:
            return buildBooking(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_TICKET_UNPARKED:
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_TICKET:
            return mapSerTicket(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_CONTRACT:
            return buildSeasonTicketResume(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_BOOKED_PARKING_PASS:
            return mapParkingPass(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_FINE:
            return buildFine(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_VEHICLE_INSPECTION_BOOKING:
            return buildVehicleInspectionBooking(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_ROTATION:
            return buildRotation(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_COUPON_ORDER:
            return buildCouponOrder(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_COUPON_ORDER_FEE:
            return buildCouponOrderFee(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_REFUEL_TICKET:
            return buildRefuelTicket(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_DEPOSIT_COUPON:
            return buildDepositCoupon(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_MOBE_QUOTA:
            return buildToll(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_DEPOSIT_COUPON_ORDER_REFUND:
            return buildCouponOrder(entity.coupon_order)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_DEPOSIT:
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_DEPOSIT_AUTO:
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_DEPOSIT_AUTO_FORCEFULLY:
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_DEPOSIT_EMOV:
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_DEPOSIT_EYSA:
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_DEPOSIT_MUTUA_MADRILENA:
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_DEPOSIT_PROMO:
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_DEPOSIT_WALLET_DEBT:
            return buildDeposits(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_TAKSEE_TRANSACTION:
            return buildTakseeTransaction(entity)
        case WALLET_MOVEMENTS.WALLET_MOVEMENT_TYPE_PAGATELIA_TOLL:
            return buildToll({...entity, showBreakdown: movement.show_breakdown || false})
    }
    return {}
}

export function buildWalletMovement (movement) {
    return ({
        date: movement.date,
        title: movement.title,
        description: movement.description,
        qty: movement.qty,
        user: buildUser(movement.user),
        entityType: movement.type_entity,
        type: movement.type,
        entity: movement.entity ? buildEntity(movement) : {},
    })
}
