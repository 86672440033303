export function buildTowns (towns) {
    return towns.map(buildTown)
}

export function buildTown (town) {
    return {
        ...town,
    }
}

export function buildSerTowns (towns) {
    return towns.map(buildSerTown)
}

export function buildSerTown (town) {
    return {
        id: town.id,
        name: town.name,
        serFineCancelType: town.ser_fine_cancel_type,
        townId: town.town_id,
    }
}
