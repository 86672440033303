import Routes from '../routes/routes'

import { buildGetApi } from './helpers'

import { buildBooking, mapBooking } from './converters/booking'
import buildOptions from './buildApiOptions'

export default buildGetApi({
    route: Routes.apiBookingPath,
    buildResult: buildBooking,
    mapParams: mapBooking,
    buildOptions,
})
