import Routes from '../routes/routes'
import { buildPutApi } from './helpers'
import { buildUser } from './converters'

function mapPayload ({ firstName: name, lastName: lastname, phone, postalCode }) {
    return {
        name,
        lastname,
        phone,
        post_code: postalCode,
    }
}

export default buildPutApi({
    route: Routes.apiUpdateUserData,
    buildResult: buildUser,
    mapPayload,
    mapParams: () => {},
})
