import Routes from '../routes/routes'
import buildOptions from './buildApiOptions'
import { buildPrebooking, mapPrebooking } from './converters'
import { buildGetApi } from './helpers'

export default buildGetApi({
    route: Routes.apiGetPrebooking,
    buildResult: buildPrebooking,
    mapParams: mapPrebooking,
    buildOptions,
})
