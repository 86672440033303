export function buildFineReport (item) {
    return {
        id: item.id,
        idFinesRequest: item.id_fines_request,
        publicationDate: item.publication_date,
        organism: item.organism,
        numberPlate: item.number_plate,
        fileNumber: item.file_number,
        qty: item.qty_amount,
        infractionDate: item.infraction_date,
        drivingLicensePointsTaken: item.driving_license_points_taken,
        infractionDescription: item.infraction_description,
        requestStartDate: item.request_start_date,
        requestEndDate: item.request_end_date,
    }
}
