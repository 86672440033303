import { buildRate } from './rentalCarBooking'
import { RENTAL_CAR_CONTRACT_STATUS as STATUS } from 'commons/js/constants'
const mapPoi = (poi) => {
    if (!poi) {
        return null
    }
    const { id, type, name } = poi
    return ({
        id,
        type,
        name,
    })
}

export const isActive = (status) => {
    return status === STATUS.RENTAL_CAR_CONTRACT_ACTIVE || status === STATUS.RENTAL_CAR_CONTRACT_ACTIVE_UNCONFIRMED
}

export const getCancelled = (status) => {
    return !!status && !isActive(status) && (status !== STATUS.RENTAL_CAR_CONTRACT_FINISHED) ? status : null
}

export function buildRentalCarContract ({ response }) {
    const {
        id,
        supplierReference,
        startDate,
        endDate,
        originPOI,
        destinationPOI,
        footerConfig,
        rate,
        isCancelable,
        status,
        priceDetails,
        fixedCoverages,
    } = response.bookingDetail
    const rateData = buildRate(rate)

    return {
        ...rateData,
        idRate: rate.id,
        id,
        supplierReference,
        startDate: startDate && (startDate / 1000),
        endDate: endDate && (endDate / 1000),
        originPOI: {
            ...originPOI && mapPoi(originPOI),
            address: rateData.originStationAddress,
            code: rateData.originStationCode,
            lat: rateData.originStationLat,
            lon: rateData.originStationLon,
            stationName: rateData.originStationName,
            phoneNumber: rateData.originStationPhoneNumber,
        },
        destinationPOI: {
            ...destinationPOI && mapPoi(destinationPOI),
            address: rateData.destinationStationAddress,
            code: rateData.destinationStationCode,
            lat: rateData.destinationStationLat,
            lon: rateData.destinationStationLon,
            stationName: rateData.destinationStationName,
            phoneNumber: rateData.destinationStationPhoneNumber,
        },
        footerConfig: footerConfig && footerConfig.map(({title, value}) => ({ title, value })),
        isCancelable,
        active: isActive(status),
        cancelled: getCancelled(status),
        priceDetails,
        fixedCoverages,
    }
}
