import Routes from '../../routes/routes'
import { buildVehicleInspectionBooking } from '../converters'
import { buildDelApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildDelApi({
    route: Routes.apiCancelVehicleInspectionBookingPath,
    mapParams: ({ bookingId }) => ({ bookingId }),
    buildResult: buildVehicleInspectionBooking,
    buildOptions,
})
