import Routes from '../routes/routes'
import { formatDate, parseTimestamp, formatDay } from '../util/dateConverter'
import buildOptions from './buildApiOptions'
import { buildBooking, mapBookingService } from './converters'
import { buildBookingError } from './converters/booking'
import { buildPostApi } from './helpers'
import {
    DATETIME_FORMAT_WITH_SECONDS,
} from 'commons/js/constants'

function mapBookingForExistingUser (booking) {
    return {
        userId: booking.user.id,
        birthday: booking.birthday && booking.birthday.format('YYYY-MM-DD'),
        parkingId: booking.parkingId,
        productId: booking.bookingProductId,
        walletId: booking.wallet.id,
        userName: booking.user.firstName,
        userLastName: booking.user.lastName,
        userEmail: booking.user.email,
        numberPlate: booking.vehicle.numberPlate,
        userPhone: booking.user.phone,
        start: formatDate(parseTimestamp(booking.startDate), DATETIME_FORMAT_WITH_SECONDS),
        end: formatDay(parseTimestamp(booking.endDate), DATETIME_FORMAT_WITH_SECONDS),
        promotionalCode: booking.promoCode,
        // Force new promotional code logic
        validatePromotionalCode: true,
        setup_intent_id: booking.creditCard.id,
        bookingAddedServices: booking.services.filter(({serviceId}) => serviceId !== null).map(mapBookingService),
    }
}
export default buildPostApi({
    route: Routes.apiCreateBooking,
    mapPayload: mapBookingForExistingUser,
    mapParams: () => {},
    buildResult: buildBooking,
    buildOptions,
    buildError: buildBookingError,
})
