import { localStorage as storage } from '@elparking/utils'
import { apiLocale } from 'commons/js/locale'

function getAuthToken () {
    try {
      return storage.get('AUTH_TOKEN')
    } catch (e) {
      return null
    }
}

const buildOptions = (params = {}) => {
    const bearer = getAuthToken()
    const options = {
        credentials: 'omit',
        mode: 'cors',
        referrerPolicy: 'origin',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Authorization, Accept, Origin, Content-Type, ep-app-name, ep-app-lang, ep-app-so, ep-app-os',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    }

    if (bearer) {
        options.headers['ep-app-name'] = 'elparking'
        options.headers['ep-app-lang'] = apiLocale
        options.headers['ep-app-so'] = 'web'
        options.headers['ep-app-os'] = 'web'
        options.headers['Authorization'] = 'Bearer ' + bearer
    }

    return {
        ...options,
        ...params,
    }
}

export default buildOptions
