import Routes from '../../routes/routes'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiGetFinePrices,
    buildResult: (prices) => prices.map((price) => ({
        id: price.id,
        type: price.type,
        name: price.name,
        description: price.description,
        price: price.price,
    })),
    buildOptions,
})
