import Routes from '../routes/routes'
import { buildPostApi } from './helpers'

function mapPayload (params) {
    return {
        email: params.email,
        reCaptcha: params.reCaptcha,
    }
}

export default buildPostApi({
    route: Routes.apiRequestResetPassword,
    mapParams: () => {},
    mapPayload,
})
