import Routes from '../../routes/routes'
import { buildPostApi } from '../helpers'
import buildOptions from './buildOptions'
import { buildRentalCarContract } from '../converters/rentalCarContract'
import moment from 'moment'

export default buildPostApi({
    route: Routes.createRentalCarContract,
    buildResult: buildRentalCarContract,
    mapPayload: ({
      uuid,
      id,
      firstName,
      lastName,
      mobileNumber,
      birthDate,
      flightNumber,
      trainNumber,
      acceptedTerms,
      email,
    }) => ({
        uuid,
        idUnborn: id,
        driverData: {
            firstName,
            lastName,
            mobileNumber,
            birthDate: moment(birthDate, 'YYYY-M-D').format('YYYY/MM/DD'),
            flightNumber: flightNumber || null,
            trainNumber: trainNumber || null,
            acceptedTerms,
            email,
        },
    }),
    buildOptions,
})
