import Routes from '../routes/routes'
import { buildWalletPreferences } from './converters/wallet'

import { buildGetApi } from './helpers'

function mapParams ({ id }) {
    return { walletId: id }
}

export default buildGetApi({
    route: Routes.apiGetWalletPreferences,
    buildResult: buildWalletPreferences,
    mapParams,
})
