import {
    buildVehicleInspectionProducts,
    mapSearchVehicleInspectionProducts,
} from '../converters'
import { buildGetApi } from '../helpers'
import Routes from '../../routes/routes'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiRequestVehicleInspectionProducts,
    buildResult: buildVehicleInspectionProducts,
    mapParams: mapSearchVehicleInspectionProducts,
    buildOptions,
})
