import Routes from '../routes/routes'
import { buildPostApi } from './helpers'

export function mapParams () {
    return {}
}

export function mapPayload ({ idWallet, setupIntent, birthday }) {
    return {
        default: true,
        setup_intent_id: setupIntent,
        id_wallet: idWallet,
        birthday: birthday.format('YYYY-MM-DD'),
    }
}

export default buildPostApi({
    route: Routes.apiCreateCreditCard,
    mapPayload,
    mapParams,
})
