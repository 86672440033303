import Routes from '../routes/routes'
import { buildGetApi } from './helpers'

import { buildBasicVehicle } from './converters/vehicle'

function buildVehicles (result) {
    return result
        .sort((a, b) => b.id - a.id)
        .map(buildBasicVehicle)
}

export default buildGetApi({
    route: Routes.apiGetVehicles,
    buildResult: buildVehicles,
})
