import Routes from '../../routes/routes'
import { buildPostApi } from '../helpers'
import buildOptions from './buildOptions'
import { buildRentalCarBooking } from '../converters/rentalCarBooking'
export function mapPayload ({idSearch, idRate}) {
    return {
        idSearch,
        idRate,
    }
}

export default buildPostApi({
    route: Routes.apiCarRentalBuildBooking,
    mapPayload,
    buildOptions,
    buildResult: ({response}) => {
        const { unborn } = response
        return buildRentalCarBooking(unborn)
    },
})
