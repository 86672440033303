import Routes from '../routes/routes'
import { buildFine } from './converters/fine'
import { buildGetApi } from './helpers'

function buildError (error) {
    const errorStatus = error.response ? error.response.status : error.name
    return {
        status: errorStatus,
    }
}
export default buildGetApi({
    route: Routes.apiCheckSerFine,
    buildResult: buildFine,
    buildError: buildError,
    mapParams: ({ idTown: townId, bulletinNumber, numberPlate }) => ({
        townId,
        number_plate: numberPlate,
        bulletin_number: bulletinNumber,
    }),
})
