import Routes from '../routes/routes'
import buildOptions from './buildApiOptions'
import { buildOfferedParkingPass } from './converters/offeredParkingPass'
import { buildGetApi } from './helpers'

export default buildGetApi({
    route: Routes.apiOfferedParkingPassPath,
    buildResult: buildOfferedParkingPass,
    mapParams: ({ id, parkingId }) => ({ parkingId, passId: id }),
    buildOptions,
})
