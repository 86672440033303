import {
    CONTRACT_TYPE_BOOKING,
    CONTRACT_TYPE_ROTATION,
    CONTRACT_TYPE_SEASON_TICKET,
    CONTRACT_TYPE_SER_TICKET,
    CONTRACT_TYPE_PARKING_PASS,
    CONTRACT_TYPE_CHARGING_POINT_BOOKING,
    CONTRACT_TYPE_VEHICLE_INSPECTION_BOOKING_PASS,
    CONTRACT_TYPE_WORKSHOP_BOOKING,
} from 'commons/js/constants'

import { mapPaginator } from './paginator'

import {
    mapRotation,
    mapBooking,
    mapSerTicket,
    mapSeasonTicket,
    mapParkingPass,
    mapVehicleInspectionBooking,
    mapChargingPointBooking,
    mapWorkshopBooking,
} from './contracts'

const CONTRACT_DATA_FIELD_BOOKING = 'booking'
const CONTRACT_DATA_FIELD_ROTATION = 'rotation'
const CONTRACT_DATA_FIELD_SEASON_TICKET = 'seasonTicketContract'
const CONTRACT_DATA_FIELD_PARKING_PASS = 'bookedParkingPass'
const CONTRACT_DATA_FIELD_SER_TICKET = 'serTicket'
const CONTRACT_DATA_FIELD_VEHICLE_INSPECTION_BOOKING = 'vehicleInspectionBooking'
const CONTRACT_DATA_FIELD_CHARGING_POINT_BOOKING = 'chargingPointBooking'
const CONTRACT_DATA_FIELD_WORKSHOP_BOOKING = 'workshopBooking'

const contractDataFieldMap = {
    [CONTRACT_TYPE_ROTATION]: CONTRACT_DATA_FIELD_ROTATION,
    [CONTRACT_TYPE_BOOKING]: CONTRACT_DATA_FIELD_BOOKING,
    [CONTRACT_TYPE_SEASON_TICKET]: CONTRACT_DATA_FIELD_SEASON_TICKET,
    [CONTRACT_TYPE_PARKING_PASS]: CONTRACT_DATA_FIELD_PARKING_PASS,
    [CONTRACT_TYPE_SER_TICKET]: CONTRACT_DATA_FIELD_SER_TICKET,
    [CONTRACT_TYPE_CHARGING_POINT_BOOKING]: CONTRACT_DATA_FIELD_CHARGING_POINT_BOOKING,
    [CONTRACT_TYPE_VEHICLE_INSPECTION_BOOKING_PASS]: CONTRACT_DATA_FIELD_VEHICLE_INSPECTION_BOOKING,
    [CONTRACT_TYPE_WORKSHOP_BOOKING]: CONTRACT_DATA_FIELD_WORKSHOP_BOOKING,
}

const contractDataMapperMap = {
    [CONTRACT_TYPE_BOOKING]: mapBooking,
    [CONTRACT_TYPE_ROTATION]: mapRotation,
    [CONTRACT_TYPE_SER_TICKET]: mapSerTicket,
    [CONTRACT_TYPE_PARKING_PASS]: mapParkingPass,
    [CONTRACT_TYPE_SEASON_TICKET]: mapSeasonTicket,
    [CONTRACT_TYPE_VEHICLE_INSPECTION_BOOKING_PASS]: mapVehicleInspectionBooking,
    [CONTRACT_TYPE_CHARGING_POINT_BOOKING]: mapChargingPointBooking,
    [CONTRACT_TYPE_WORKSHOP_BOOKING]: mapWorkshopBooking,
}

const identityDataMapper = (data) => data

function mapContractData (contract) {
    const dataField = contractDataFieldMap[contract.contract_type]
    const dataMapper = contractDataMapperMap[contract.contract_type] || identityDataMapper
    const contractData = contract[dataField] || {}

    return dataMapper(contractData)
}

function buildInactiveContract (contract) {
    return {
        id: contract.id_contract,
        type: contract.contract_type,
        data: mapContractData(contract),
    }
}

export function buildInactiveContracts (contracts) {
    return mapPaginator(contracts, buildInactiveContract)
}
