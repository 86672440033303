import Routes from '../routes/routes'
import { buildPutApi } from './helpers'
import { mapEditVehicle, buildVehicle } from './converters/vehicle'

export default buildPutApi({
    mapPayload: mapEditVehicle,
    route: Routes.apiUpdateVehicle,
    buildResult: buildVehicle,
    mapParams: ({ id: idVehicle }) => ({ idVehicle }),
})
