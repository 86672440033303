import Routes from '../routes/routes'

import { buildGetApi } from './helpers'

import { buildBookedParkingPass } from './converters/bookedParkingPass'
import buildOptions from './buildApiOptions'

function mapParams ({ id, idUser }) {
    return { passId: id, idUser }
}

export default buildGetApi({
    route: Routes.apiGetBookedParkingPass,
    buildResult: buildBookedParkingPass,
    mapParams,
    buildOptions,
})
