import Routes from '../../routes/routes'
import { buildPostApi } from '../helpers'
import { buildSubscription } from './converters/subscription'
import buildOptions from '../buildApiOptions'

export default buildPostApi({
    route: Routes.apiGetFinesNotifierSubscriptionCreateForDni,
    buildResult: buildSubscription,
    mapParams: ({ subscriptionId }) => ({ subscriptionId }),
    mapPayload: ({
        userId,
        walletId,
        dni,
        drivingLicenseExpeditionDate,
    }) => ({
        userId,
        walletId,
        dni,
        driving_license_expedition_date: drivingLicenseExpeditionDate,
    }),
    buildOptions,
})
