const buildOptions = (params = {}) => ({
    credentials: undefined,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': params.token,
    },
})

export default buildOptions
