import Routes from '../../routes/routes'
import { buildDelApi } from '../helpers'
import { buildSubscription } from './converters/subscription'
import buildOptions from '../buildApiOptions'

export default buildDelApi({
    route: Routes.apiFinesNotifierSubscriptionCancel,
    buildResult: buildSubscription,
    mapParams: ({ subscriptionId }) => ({ subscriptionId }),
    buildOptions,
})
