import Routes from '../routes/routes'
import { buildPostApi } from './helpers'
import { buildSerTicket } from './converters/serTicket'
import { mapSerStepsInfo } from './converters/serStep'

const mapPayload = ({
    walletId,
    fareQty,
    numberPlate,
    serZoneId,
    serRateId,
    vehicleId,
    startDate,
    stayDuration,
    latitude = 0,
    longitude = 0,
    commission,
    commissionQty,
    commissionVat,
    serStepInfo,
    endDate,
    originalNonDiscountedPriceQty,
    pollutionCoefficient,
}) => ({
    walletId,
    fare_qty: fareQty,
    number_plate: numberPlate,
    id_ser_zone: serZoneId,
    id_ser_rate: serRateId,
    id_vehicle: vehicleId,
    start_date: startDate,
    stay_duration: stayDuration,
    latitude,
    longitude,
    commission,
    commission_qty: commissionQty,
    commission_vat: commissionVat,
    step_request: mapSerStepsInfo(serStepInfo),
    end_date: endDate,
    original_non_discounted_price_qty: originalNonDiscountedPriceQty,
    pollution_coefficient: pollutionCoefficient,
})

export default buildPostApi({
    route: Routes.apiPaySerTicket,
    buildResult: buildSerTicket,
    mapParams: () => {},
    mapPayload,
})
