import Routes from '../../routes/routes'
import { buildGetApi } from '../helpers'
import { buildSubscription } from './converters/subscription'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiGetFinesNotifierSubscriptionGetAllActive,
    buildResult: (items) => items.map(buildSubscription),
    buildOptions,
})
