import moment from 'moment-timezone'

const toTimestamp = (date) => !date
    ? null
    : moment.tz(date * 1000, 'UTC').unix()

export function buildSerTicket (data) {
    const endDate = toTimestamp(data.end_date)
    return {
        id: data.id,
        startDate: toTimestamp(data.start_date),
        endDate,
        finished: moment.tz().unix() > endDate,
        canUnPark: data.can_drive_off_web,
        unParked: data.unparked,
        zone: {
            name: data.zone.name,
            id: data.zone.id,
        },
        rate: data.rate
            ? {
                name: data.rate.name,
            }
            : null,
        vehicle: {
            brand: data.vehicle.brand.name,
            model: data.vehicle.model.name,
            numberPlate: data.vehicle.number_plate,
            dgtEnvironmentalLabelNumber: data.vehicle.dgt_environmental_label_number,
        },
        priceQty: data.totalAmountQty,
        fareQty: data.fare_qty,
        unparkingPriceQty: data.unparkingTotalAmountQty,
        unparkingDate: toTimestamp(data.unparking_date),
        totalRefundQty: data.totalRefundQty,
        stayDuration: data.stay_duration,
        unparkingStayDuration: data.unparking_stay_duration,
        timeZone: data.timeZone,
        town: data.zone.town,
        commissionAmountQty: data.commissionAmountQty,
        commissionAmountWithTaxQty: data.commissionAmountWithTaxQty,
        commissionRefundWithTaxQty: data.commissionRefundWithTaxQty,
        unparkingCommissionAmountWithTaxQty: data.unparkingCommissionAmountWithTaxQty,
        unparkingTotalAmountQty: data.unparkingTotalAmountQty,
        unparkingFareQty: data.unparking_fare_qty,
    }
}
