import moment from 'moment'
import { localStorage } from '@elparking/utils'

export function buildSessionUser (user) {
    localStorage.save('AUTH_TOKEN', user.keycloakToken && user.keycloakToken.access_token)
    return buildUser(user)
}

export function buildUser (user) {
    return {
        id: user.id,
        firstName: user.name,
        lastName: user.lastname,
        phone: user.phone,
        email: user.email,
        postalCode: user.post_code,
        birthday: user.birthday && moment.utc(user.birthday * 1000).format('YYYY-MM-DD'),
        preferences: user.preferencesElparking && buildPreferences(user.preferencesElparking),
        deleted: !!user.deleted_at,
        keycloakToken: user.keycloakToken
            ? {
                access_token: user.keycloakToken.access_token,
                refresh_token: user.keycloakToken.refresh_token,
                token_type: user.keycloakToken.token_type,
                id_token: user.keycloakToken.id_token,
                session_state: user.keycloakToken.session_state,
                jti: user.keycloakToken.jti,
                sub: user.keycloakToken.sub,
                sid: user.keycloakToken.sid,
                eid: user.keycloakToken.eid,
            }
            : null,
    }
}

export function buildPreferences (preferences) {
    return {
        ticketNotification: preferences.ticket_notification,
        emailLanguage: preferences.email_language,
        autoDeposit: preferences.auto_deposit,
        autoDepositAmount: preferences.auto_deposit_amount,
        autoDepositThreshold: preferences.auto_deposit_threshold,
    }
}

export function mapPreferences (preferences) {
    return {
        ticket_notification: preferences.ticketNotification,
        email_language: preferences.emailLanguage,
    }
}
