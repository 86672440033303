import Routes from '../routes/routes'
import { buildPostApi } from './helpers'

export default buildPostApi({
    route: Routes.apiPaySerFine,
    buildResult: () => true,
    mapParams: ({ wallet }) => ({
        idWallet: wallet.id,
    }),
    mapPayload: ({ fine }) => ({
        bulletin_number: fine.bulletinNumber,
        id_ser_town: fine.town.id,
        fare_qty: fine.fareQty,
        id_auth: fine.idAuth,
    }),
})
