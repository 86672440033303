import Routes from '../routes/routes'
import { buildPutApi } from './helpers'
import buildOptions from './buildApiOptions'
import { buildPrebooking, mapPrebooking, mapUpdatePrebooking } from './converters'
export default buildPutApi({
    route: Routes.apiUpdatePrebooking,
    buildResult: buildPrebooking,
    mapParams: mapPrebooking,
    mapPayload: mapUpdatePrebooking,
    buildOptions,
})
