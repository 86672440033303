import Routes from '../routes/routes'
import { buildSerTicket } from './converters/serTicket'
import { buildGetApi } from './helpers'

export default buildGetApi({
    route: Routes.apiSerTicketPath,
    buildResult: buildSerTicket,
    mapParams: (data) => {
        return { id: data.serTicketId }
    },
})
