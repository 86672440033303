import Routes from '../../routes/routes'
import { buildSeasonTicketContract } from '../converters/seasonTicketContract'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiSeasonTicketContractPath,
    buildResult: buildSeasonTicketContract,
    mapParams: (data) => {
        return { id: data.seasonTicketContractId }
    },
    buildOptions,
})
