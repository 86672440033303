import { buildParking } from './parking'

export function buildSeasonTicket (data) {
    return {
        id: data.id,
        parking: data.parking && buildParking(data.parking),
        name: data.name,
        identifier: data.identifier,
    }
}

export function buildSeasonTicketWithPrice (data) {
    return ({
        ...buildSeasonTicket(data.seasonTicket),
        monthlyPrice: data.price,
        startDate: data.date,
        endDate: data.seasonTicket.end_date || null,
        parking: data.parking && buildParking(data.parking),
        priceQty: data.seasonTicket.price_qty,
        hasPromotionalPrice: data.seasonTicket.has_promotional_price,
        promotionalPrice: data.seasonTicket.promotional_price_qty,
        promotionalPriceEndDate: data.seasonTicket.promotional_price_estimated_end_date,
    })
}
