import Routes from '../routes/routes'
import buildOptions from './buildApiOptions'
import { buildParking } from './converters/parking'
import { buildGetApi } from './helpers'
import { buildClientError } from './converters'

export default buildGetApi({
    route: Routes.apiGetParking,
    buildResult: buildParking,
    mapParams: ({ parkingId }) => ({
        idParking: parkingId,
    }),
    buildOptions,
    buildError: buildClientError,
})
