import Routes from '../../routes/routes'
import { buildSeasonTicketWithPrice } from '../converters/seasonTicket'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiSeasonTicketPricePath,
    buildResult: buildSeasonTicketWithPrice,
    mapParams: ({ seasonTicketId, startDate }) => ({ seasonTicketId, startDate }),
    buildOptions,
})
