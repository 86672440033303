import Routes from '../routes/routes'
import { buildGetApi } from './helpers'
import { buildClientError } from 'commons/js/api/converters'
import { UnprocessableEntityError } from '../client/errors'

function mapParams (params) {
    return {
        id_product: params.productId,
        num_products: params.units,
        promo_code: params.promoCode,
    }
}

function buildResult (price) {
    return ({
        promoCodeDiscountedPrice: price.promotional_code_discounted_price,
        singleProductQuotaPrice: price.quota_base_price,
        totalProductBasePrice: price.total_base_price,
        totalQuotaBasePrice: price.total_quota_base_price,
        taxQty: price.total_tax_price,
        totalShippingBasePrice: price.total_shipping_base_price,
        totalPriceQty: price.total_price_with_tax,
        brand: price.product.brand,
    })
}

function buildError (error) {
    if (error instanceof UnprocessableEntityError) {
        return {
            ...buildClientError(error),
            status: error.response.status,
            data: {
                promoCode: error && error.response && error.response.data && error.response.data.promotional_code,
            },
        }
    }

    return buildClientError(error)
}

export default buildGetApi({
    route: Routes.apiGetViatProducts,
    buildResult,
    mapParams,
    buildError,
})
