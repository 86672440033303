import Routes from '../../routes/routes'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'

export default buildGetApi({
    route: Routes.apiCheckSeasonTicketAvailability,
    mapParams: ({ seasonTicketId, vehicleId, startDate }) => ({ seasonTicketId, vehicleId, startDate }),
    buildResult: () => true,
    buildOptions,
})
