import Routes from '../routes/routes'
import { buildSessionUser } from './converters/user'
import { buildGetApi, buildGenericError } from './helpers'

function buildResult (user) {
    return buildSessionUser(user)
}

export default buildGetApi({
    route: Routes.apiGetUser,
    buildResult,
    buildError: (params) => {
        // eslint-disable-next-line no-undef
        localStorage.setItem('AUTH_TOKEN', null)
        return buildGenericError(params)
    },
})
