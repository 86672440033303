import Routes from '../routes/routes'
import buildOptions from './buildApiOptions'
import { buildBookedParkingPass } from './converters/bookedParkingPass'
import { buildPostApi } from './helpers'

export default buildPostApi({
    route: Routes.apiCreateBookedParkingPass,
    mapParams: ({idUser}) => ({ idUser }),
    buildResult: buildBookedParkingPass,
    buildOptions,
    mapPayload: ({ walletId, vehicleId, offeredParkingPassId }) => ({
        id_offered_parking_pass: offeredParkingPassId,
        id_vehicle: vehicleId,
        id_wallet: walletId,
    }),
})
