import Routes from '../../routes/routes'
import { buildGetApi } from '../helpers'
import buildOptions from '../buildApiOptions'

function mapParams ({ stationId, stationProductId, day }) {
    return {
        stationId,
        stationProductId,
        timestamp00: day,
    }
}

export default buildGetApi({
    route: Routes.apiGetVehicleInspectionStationOccupationIntraDay,
    mapParams,
    buildOptions,
    buildResult: (list) => list.map(timestamp => parseInt(timestamp)),
})
