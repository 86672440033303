import Routes from '../../routes/routes'
import { buildPostApi } from '../helpers'
import buildOptions from './buildOptions'

export default buildPostApi({
    route: Routes.apiUserServiceRegisterIntent,
    mapPayload: ({
        email,
        termsAndConditionsDocumentVersion,
    }) => ({
        email,
        termsAndConditionsDocumentVersion,
    }),
    buildResult: () => true,
    buildOptions,
})
