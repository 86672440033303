export function mapAccount (account) {
    return {
        name: account.firstName,
        lastname: account.lastName,
        email: account.email,
        password: account.password,
        country_code: account.country,
        promotional_code: account.promoCode,
        accept_conditions: account.acceptConditions || account.terms,
        reCaptcha: account.reCaptcha,
    }
}

export function mapLogin (login) {
    return {
        email: login.email,
        password: login.password,
        rememberMe: login.rememberMe,
    }
}
