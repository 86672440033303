export function buildTakseeTransaction (takseeTransaction) {
    const percentage = parseFloat(takseeTransaction.tax_percentage)
    const amount = takseeTransaction.price.amount
    const basePriceQty = {
        ...takseeTransaction.price,
        amount: amount * (100 - percentage) / 100,
    }
    const taxPrice = {
        ...takseeTransaction.price,
        amount: amount * percentage / 100,
    }
    return {
        id: takseeTransaction.id,
        idExternal: takseeTransaction.id_external,
        description: takseeTransaction.description,
        priceQty: takseeTransaction.price,
        basePriceQty,
        tax: {
            percentage: takseeTransaction.tax_percentage,
            name: takseeTransaction.tax_name,
            qty: taxPrice,
        },
    }
}
