import Routes from '../../routes/routes'
import { buildGetApi } from '../helpers'
import buildOptions from './buildOptions'

export default buildGetApi({
    route: Routes.apiCarRentalGetPois,
    buildResult: (result) => {
        return (result && result.response && result.response.responseList)
        ? result.response.responseList.map(
            ({ id, title, subTitle, type }) => ({ id, name: subTitle, description: title, type })
        )
        : []
    },
    buildOptions,
})
