export function buildSerTownInfo (info) {
    return {
        timetable_description: info.timetable_description,
        latitude: info.latitude,
        longitude: info.longitude,
        serTownId: info.id_ser_town,
        urlMedia: info.url_media,
        canPark: info.can_park,
        canUpPark: info.can_drive_off_web,
        canCancelFine: info.can_cancel_fine_web,
    }
}
