export function buildPointReport (item) {
    return {
        dni: item.dni,
        currentBalancePoints: item.current_balance_points,
        currentBalanceDate: item.current_balance_date,
        previousBalancePoints: item.previous_balance_points,
        previousBalanceDate: item.previous_balance_date,
        timeZone: item.time_zone_name,
    }
}
