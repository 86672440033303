import { devices, isWebview } from '@elparking/utils'

const getEntryChannel = () => {
    const webview = isWebview()
    let entryChannel = 'web'
    if (webview && devices.isAndroid()) {
        entryChannel = 'android'
    } else if (webview && devices.isIphone()) {
        entryChannel = 'ios'
    }
    return entryChannel
}

export default getEntryChannel
